<template>
  <!-- 
    Stop dialog close so users need to click the button to close it,
    otherwise you can generate the credentials and fail to copy them
   -->
  <v-dialog id="dialog" v-model="dialog" max-width="750px" persistent>
     <template v-slot:activator="{ on }">
      <v-btn v-on="on" text class="primary--text">
        <v-icon class="mr-1">add_box</v-icon>
        Create Key
      </v-btn>
    </template>
    <!-- Show create form -->
    <v-card v-if="!createdUserAccessKey">
      <v-card-title>Create New Access Key</v-card-title>
      <v-card-text>
        <form v-on:submit.prevent="createUserAccessKey()">
          <v-text-field
            label="Key description" 
            v-model="userAccessKey.description" 
            />          
          
          <v-btn v-show="false" hidden type="submit">
          </v-btn>
        </form>
        <div v-show="error">
          <v-alert type="error" outlined>
            {{ error }}
          </v-alert>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn text v-on:click.native="resetForm()" class="primary--text">Close</v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="!createdUserAccessKey" text :disabled="loading" :loading="loading" v-on:click.native.stop="createUserAccessKey()" class="primary">Submit</v-btn>
      </v-card-actions>
    </v-card>
    <!-- Once created, showing the key and commands -->
    <v-card v-else>
      <v-card-title>Access Key Created</v-card-title>
      <v-card-text>
        Your access key:
        <v-container fluid>
          
          <markup language="bash" :code="createdUserAccessKey.value" :inline="false"></markup>
         </v-container>
      </v-card-text>

      <v-card-text>       
         To configure synpse CLI, select project, namespace and run the given command.
        <v-container fluid class="mt-5">
          <v-row align="center">
            <v-select
              :items="projects"
              label="Project ID"
              v-model="projectId"          
              item-text="project.name"
              item-value="project.id"
              outlined
            ></v-select>
          </v-row>
          <v-row align="center">
            <v-select
              :items="namespaces"
              label="Namespace ID"
              v-model="namespaceId"          
              item-text="name"
              item-value="id"
              outlined
            ></v-select>
          </v-row>
        
          <!-- <v-row align="center" class="mb-2 mt-4"> -->
           
            <markup language="bash" :code="configureCLICommand" :inline="false"></markup>
          <!-- </v-row> -->
        </v-container>
      </v-card-text>
      <v-card-text>
          <p>
            <em>Please note that once you close this window you will not be able to see the access key again as it
              is now encrypted. If you lose your access key, just generate a new one.</em>
          </p>
          <p>
            <b>Windows users</b> <em>Depending on the terminal, you might need to remove \ from the command.</em>
          </p>
        </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text v-on:click.native="resetForm()" class="primary--text">Close, I have copied credentials</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="stylus">
  .addbtn
    margin: 0px
    top: auto
    right: 20px
    bottom: 20px
    left: auto
    position: fixed
    z-index: 1
</style>

<script>
import Markup from '../helpers/Markup';

  export default {
    components: {
      Markup
    },
    data () {
      return {
        dialog: false,

        userAccessKey: {
          description: ''
        },

        projectId: '',
        namespaceId: ''
      }
    },

    watch: {
      'projectId' () {
        // When user is selecting projects, loading appropriate namespaces 
        // too and also reseting the current selection
        this.selectedNamespaceId = ''         
        let q = {
          project: this.projectId
        }        
        this.$store.dispatch('ListNamespaces', q)
      }
    },

    mounted() {
      if (this.selectedProjectId) {
        this.projectId = this.selectedProjectId
      }
      if (this.selectedNamespaceId) {
        this.namespaceId = this.selectedNamespaceId
      }      
    },

    computed: {
      loading() {
        return this.$store.state.iam.loading
      },
      error() {
        return this.$store.state.iam.error
      },
      createdUserAccessKey() {
        return this.$store.state.iam.createdUserAccessKey
      },
      selectedProjectId () {
        return this.$store.state.project.selectedProjectId
      },
      configureCLICommand() {
        return `synpse configure --project ${this.projectId} \\\n --namespace ${this.namespaceId} \\\n --access-key ${this.createdUserAccessKey.value} \\\n --controller-uri ${window.location.protocol}//${window.location.host}/api \n`
      },
      projects () {        
        return this.$store.state.project.memberships
      },
      namespaces() {
        return this.$store.state.namespace.namespaces
      }
    },

    methods: {
      createUserAccessKey () {
        this.$store.dispatch('CreateUserAccessKey', this.userAccessKey).then(() => {
          // Selecting the first namespace if nothing is set
          if (this.namespaceId === '' && this.namespaces.length > 0) {
            this.namespaceId = this.namespaces[0].id
          }

          this.$store.dispatch('ListUserAccessKeys')          
        })
      },

      resetForm: function () {
        // closing dialog
        this.dialog = false
        this.$store.dispatch('CleanupCreatedUserAccessKey')
        // resetting form
        this.userAccessKey.description = ''
      }
    }
  }
</script>
